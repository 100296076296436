import { createSlice } from '@reduxjs/toolkit'; //importing create slice
import { RootState } from '../store';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from '../../utils';
import { getAdvisorsAction, getClientsAction, updateUsersAction } from '../actions/clientsActions';


export type AdvisorType = {
    app_user_id: string;
    email: string;
    id: string;
    user_name: string;
}

export type AccessCodeData = {
  id: string;
  expire_at: string;
}

export type Permission = {
  permissions: string[]
}

export type Shared = {
  ownerId: string;
  permission: Permission[];
}
export type Sharing = {
  guestId: string;
  permission: Permission[]
}

export type ClientType = {
    id: string;
    access_code_data: AccessCodeData[];
    app_user_id: string;
    business_name: string;
    created_at: string;
    email: string;
    role_type: string;
    roles: string[];
    user_name: string;
    advisors?: AdvisorType[];
    clients?: AdvisorType[];
    sharing?: Sharing[];
    shared?: Shared[];
}

type State = {
  loading: boolean;
  error: string | undefined;
  clients: ClientType[] | undefined;
  advisors: ClientType[] | undefined;
};

const initialState: State = {
  clients: undefined,
  advisors: undefined,
  loading: false,
  error: undefined,
};

const persistedState = { ...initialState, ...loadStateFromLocalStorage<Partial<State>>('clientsState') };

const clientsSlice = createSlice({
  name: 'clients_slice',
  initialState: persistedState,
  reducers: {
    logout: () => {
      // Reset the other state on logout
      return initialState;
    },
  },
  extraReducers: (builder) => { 
    builder.addCase(getClientsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getClientsAction.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.clients = action.payload;
        saveStateToLocalStorage('clientsState', { clients: state.clients, advisors: state.advisors});
      }
    });
    builder.addCase(getClientsAction.rejected, (state, action) => {
      state.loading = false;
      if (typeof action.payload === 'string'){
        state.error = action.payload;
      } else if(action.payload instanceof Error){
        state.error = action.payload.message;
      }else{
        state.error = 'Unknown error occurred while adding new client to waitlist!';
      }
    });
    builder.addCase(getAdvisorsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdvisorsAction.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.advisors = action.payload;
        saveStateToLocalStorage('clientsState', { clients: state.clients, advisors: state.advisors});
      }
    });
    builder.addCase(getAdvisorsAction.rejected, (state, action) => {
      state.loading = false;
      if (typeof action.payload === 'string'){
        state.error = action.payload;
      } else if(action.payload instanceof Error){
        state.error = action.payload.message;
      }else{
        state.error = 'Unknown error occurred while adding new client to waitlist!';
      }
    });
    builder.addCase(updateUsersAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUsersAction.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        action.payload.forEach(user => {
          if(user.role_type == 'client' && state.clients){
            state.clients = state.clients.map(item => item.id == user.id? user : item);
          }
          if(user.role_type == 'advisor' && state.advisors){
            state.advisors = state.advisors.map(item => item.id == user.id? user : item);
          }
        })
      
        saveStateToLocalStorage('clientsState', { clients: state.clients, advisors: state.advisors});
      }
    });
    builder.addCase(updateUsersAction.rejected, (state, action) => {
      state.loading = false;
      if (typeof action.payload === 'string'){
        state.error = action.payload;
      } else if(action.payload instanceof Error){
        state.error = action.payload.message;
      }else{
        state.error = 'Unknown error occurred while adding new client to waitlist!';
      }
    });
  },
});


export const selectAccessCodeState = (state: RootState) => state.accessCode_slice;
export const { logout } = clientsSlice.actions;

export default clientsSlice.reducer;