import React, { useEffect, useState } from "react";
import ClientAdvisorButton from "./oveview/ClientAdvisorButton";
//import CustomProgressBar from "./oveview/ProgessBar";
import UnassignedLicensesButton from "./oveview/UasignedButton";
import MarketingButton from "./oveview/MarkitingButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
//import useCustomStyles from "../hooks/useCustomStyles";
//import { accessCodesStyles } from "../assets/styles/accessCodesStyles";
import { Theme } from "../../src/types";

import {
  getAccessCodes,
  getContracts,
} from "../store/actions/accessCodeActions";
import { useNavigate } from "react-router-dom";
import useCustomStyles from "../hooks/useCustomStyles";
import { accessCodesStyles } from "../assets/styles/accessCodesStyles";
// import { selectState } from "../store/slices/notificationSlice";
import { selectState } from "../store/slices/themeSlice";
import advisors from "./advisors/advisors";
import clients from "./clients/clients";
import { getAdvisorsAction, getClientsAction } from "../store/actions/clientsActions";

function dashboard() {
  const { user } = useSelector((state: RootState) => state.auth_slice);
  //const width = window.innerWidth; // Web equivalent for width and height
  const { accessCodes, contracts } = useSelector(
    (state: RootState) => state.accessCode_slice
  );
  const { clients, advisors} = useSelector((state: RootState)=> state.clients_slice)

  const { theme } = useSelector(selectState);
  const classes = useCustomStyles(accessCodesStyles, theme);

  const [clientLicences, setClientLicences] = useState(0);
  const [advisorLicences, setAdvisorLicences] = useState(0);
  const [openLicences, setOpenLicences] = useState({ total: 0, assigned: 0 });
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleGetContracts = () => {
    dispatch(getContracts({ business_client: user?.business?.id || "" }));
  };

  useEffect(() => {
    if (advisors) {
      setAdvisorLicences(advisors.length);
    }
    if (clients) {
      setClientLicences(clients.length);
    }
  }, [advisors, clients]);


  useEffect(() => {
    if (user && user.business) {
      dispatch(
        getAccessCodes({
          business_client:
            user?.business.name == "elisyan" ? "" : user?.business?.id,
        })
      );
      dispatch(getAdvisorsAction({business_name: user?.business?.name}));
      dispatch(getClientsAction({business_name: user?.business?.name}));
    }
      handleGetContracts();
  }, []);


 /* useEffect(() => {
    // Update client and advisor licenses from accessCodes regardless of validation
    if (accessCodes) {
      let totalClientLicences = 0;
      let totalAdvisorLicences = 0;

      accessCodes.forEach((accessCode) => {
        if (accessCode.role_type === "client" && accessCode.validated) {
          totalClientLicences += 1;
        } else if (accessCode.role_type === "advisor" && accessCode.validated) {
          totalAdvisorLicences += 1;
        }
      });

      setClientLicences(totalClientLicences);
      setAdvisorLicences(totalAdvisorLicences);
    }
  }, [accessCodes]);*/

  useEffect(() => {
    if (contracts) {
      // Initialize an object to accumulate total and assigned licenses
      const totalLicences = { total: 0, assigned: 0 };

      contracts.forEach((contract) => {
        // Accumulate the total licenses based on useLimit
        totalLicences.total += contract.useLimit;

        // Accumulate the assigned licenses based on licence_count
        totalLicences.assigned += contract.licence_count;
      });

      // Update the state with the total licenses object
      setOpenLicences(totalLicences);
    }
  }, [contracts]);

  const width = window.innerWidth * 0.4; // Web equivalent for width and height

  return (
    // <div style={{ width: "300px", marginTop: "100px", alignContent: "center" }}>
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <h2 className={classes.header}> OVERVIEW</h2>
      </div>
      <div style={{ marginBottom: "40px" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(2, 1fr)`, // Two equal columns
            gap: width * 0.1,
            alignItems: "center",

            marginTop: "10px",
            alignContent: "center",
            // width: "100%",
            // justifyContent: "space-between",
            width: width,
            // border: "1px solid #ccc",
          }}
        >
          {/* <div style={{ marginRight: "auto" }}> */}
          <ClientAdvisorButton
            number={clientLicences}
            mainText={(clientLicences > 1 || clientLicences == 0)? "Clients" : "Client"}
            width={width}
            onPress={() => navigate("/clients")}
          />
          {/* </div> */}
          <ClientAdvisorButton
            number={advisorLicences}
            mainText={(advisorLicences > 1 || advisorLicences == 0) ? "Advisors" : "Advisor"}
            width={width}
            onPress={() => navigate("/advisors")}
          />
        </div>
        <UnassignedLicensesButton
          unassignedLicenseNumber={openLicences.total}
          unassignedLicenseLimitNumber={openLicences.assigned}
          mainText={"Available Licences"}
          width={width}
          onPress={() => navigate("/accessCodes")}
        />
      </div>
      {/* <p style={{ width: "75px", height: "18px", marginLeft: "20px" }}>
        Marketing
      </p>
      <MarketingButton
        unassignedLicenseNumber={0}
        unassignedLicenseLimitNumber={10}
        mainText={"Active Referral Codes"}
        width={width}
      /> */}
    </div>
    // </div>
  );
}

export default dashboard;
