import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import CustomSelect from '../../components/CustomSelectInput';
import CustomButton from '../../components/CustomButton';
import useCustomStyles from '../../hooks/useCustomStyles';
import { customStyles } from '../../assets/styles/notificationForm';
import { selectTranslationState } from '../../store/slices/languageSlice';
import { selectState } from '../../store/slices/themeSlice';
import CustomInput from '../../components/CustomInput';
import SearchableSelect from '../../components/SearchableSelect';
import { AppDispatch, RootState } from '../../store/store';
import { AccessCodeType, addAccessCode, addReferralCode, ContractType, getBusinesses, getContracts } from '../../store/actions/accessCodeActions';
import CustomMultiSelect from '../../components/CustomMultiSelect';
import validationSchema from './scripts';
import { Option } from '../../components/CustomMultiSelect';
import { listenToLicenceCountUpdates, listenToLicenceValidatedUpdates } from '../../db/listners';
import { setContracts, setLicence } from '../../store/slices/accessCodeSlice';
import { useNavigate } from 'react-router-dom';

const GenerateAccessCode = () => {
  const { t } = useSelector(selectTranslationState);
  const { theme, activeTheme } = useSelector(selectState);
  const { businesses, contracts, loading } = useSelector((state: RootState) => state.accessCode_slice);
  const classes = useCustomStyles(customStyles, theme);
  const [filteredContracts, setFilteredContracts] = useState(contracts)
  const [openLicenceCount, setOpenLicenceCount] = useState(0)
  const dispatch = useDispatch<AppDispatch>();
  const emailList = ['email1@example.com', 'email2@example.com']; // Replace with your actual email list
  

  useEffect(() => {
    // Define the function that updates the state (or dispatches a Redux action)
    const handleLicenseUpdate = (accessCode: AccessCodeType) => {
      // Example of dispatching a Redux action
      dispatch(setLicence(accessCode));
    };
    
    const updateContractsInState = (contracts: ContractType[]) => {
      // Dispatch an action to update the entire contracts list in the Redux store
      dispatch(setContracts(contracts));  // Ensure this action is set up in your Redux slice
    };
    // Start listening to licence validated updates
    const unsubscribeLicence = listenToLicenceValidatedUpdates(handleLicenseUpdate);

    // Start listening to licence count updates
    const unsubscribeContract = listenToLicenceCountUpdates(updateContractsInState);

    // Cleanup listener when the component unmounts
    return () => {
      if (unsubscribeLicence) {
        unsubscribeLicence();  // Unsubscribe from licence updates
      }
      if (unsubscribeContract) {
        unsubscribeContract();  // Unsubscribe from contract updates
      }
    };
  }, []);

  const navigate = useNavigate()

  const roleList = [
   // {label: 'Marketing', value: 'Marketing'}, 
    {label: 'CRM', value: 'CRM'},
    {label: 'Administrator', value: 'Administrator'}]
  
  const formik = useFormik({
    initialValues: {
      roles: [],
      role_type: 'client',
      codeType: '',
      contract_ref: '',
      contract_left_licences: 0,
      business_client: '',
      for_business_client: 'elisyan',
      prefix: undefined,
      partner: '',
      email: '',
      expire_at: '',
      useLimit: 1,
      number_of_codes_to_generate: 1,
    },
    validationSchema: validationSchema,
    onSubmit: async(values) => {
      if(values.codeType == 'licence-code'){
        await dispatch(addAccessCode({ 
          role_type: values.role_type, 
          roles: values.roles,
          expire_at:values.codeType=='licence-code' ? filteredContracts?.filter(item => item.id == values.contract_ref)[0].expire_at?.toString() : values.expire_at,
          codeType: values.codeType,
          contract_ref: values.contract_ref,
          business_client: values.business_client,
          number_of_codes_to_generate: values.number_of_codes_to_generate,
          navigate
        }))
      }else{
        await dispatch(addReferralCode({ 
          expire_at: values.expire_at,
          business_client: values.business_client,
          for_business_client: values.for_business_client,
          prefix: values.prefix,
          number_of_codes_to_generate: values.number_of_codes_to_generate,
          useLimit: values.useLimit || 1,
          navigate
        }))
      }
     
      },
    validateOnChange: false, // Disable validation on change
    validateOnBlur: false,   // Disable validation on blur
  }); 
  
  const { values, setFieldValue, handleBlur,handleSubmit, setFieldTouched } = formik;

  const handleGetContracts = ()=>{
    dispatch(getContracts({business_client: ""}))
  }
  const handleGetBusinesses = ()=>{
    dispatch(getBusinesses({type: ""}))
  }
  
  useEffect(() => {
    
      handleGetContracts();
    
      handleGetBusinesses();

  }, [])

  useEffect(() => {
   // console.log(' fetch contracts for business', formik.values.business_client)
    if(formik.values.business_client && typeof formik.values.business_client =='string' && formik.values.business_client.length > 0 && contracts && contracts.length > 0 ){
      setFilteredContracts(contracts.filter((item: ContractType )=> item.business_client == formik.values.business_client));  
    }
  }, [formik.values.business_client])


  // Wrapper function to handle form submission from button click
  const handleFormSubmit = async(event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
      await formik.submitForm();

   
    
   /* const isValid = await formik.validateForm(); // Validate the form
    formik.setTouched();
    // Check if there are errors in the form
    if (Object.keys(isValid).length === 0) {
      try {
        await formik.submitForm(); // Call the Formik's submit function, which should handle your API call
        // If successful, reset the form
        formik.resetForm();
      } catch (e) {
        console.log('Error while submitting the form:', e);
        // Handle error, e.g., show an error message to the user
      }
    } else {
      console.log('Form has errors:', isValid);
      // You may want to display these errors to the user
    }*/
  };
  
  const handleContractRefChange = async(e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    await setFieldValue(name, value);
    await setFieldTouched(name, true);
    const valid = await validationSchema.validateAt(name, { [name]: value });
    if(valid){
      formik.setFieldError(name, undefined);  // Clear the error if validation passes
      const selectedContract = filteredContracts?.filter(item => item.id == e.target.value)
      if(selectedContract && selectedContract?.length > 0){
        setOpenLicenceCount(selectedContract[0].useLimit - selectedContract[0].licence_count)
        setFieldValue('contract_left_licences', selectedContract[0].useLimit - selectedContract[0].licence_count)
      }
    }
   
  }

  const handleSelectFieldChange1 = async(e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) =>{
    const { name, value } =  e.target;  
    if(name == 'business_client'){
      await setFieldValue('contract_ref', '');
      setOpenLicenceCount(0)
      setFieldValue('contract_left_licences', 0)
    }
    await setFieldValue(name, value);
    await setFieldTouched(name, true);
    if(name == 'role_type' && value == 'client'){
      await setFieldValue('roles', []);
    }

    const valid = await formik.validateForm();
    if(valid){ 
      console.log('field validated', name, value)
    }else{
      console.log('field not validated', name, formik.errors)
    }
  }

  const handleSelectFieldChange2 = async(e: {name: string, value: string| number | Option[]}) =>{
    const { name, value } =  e;  
    await setFieldValue(name, value);
    await setFieldTouched(name, true);
    const valid = await formik.validateForm();
    if(valid){ 
      console.log('field validated', name, value)
    }else{
      console.log('field not validated', name, formik.errors)
    }
  }
  
  
  return (
    <div className={classes?.container}>
      <div className={classes?.notificationForm}>
        <h1 className={classes?.title}>{t[activeTheme].referralCodeScreen.form.title}</h1>
        <hr className={classes?.divider} />

        <form onSubmit={handleSubmit} className={classes?.notificationForm}>
          <CustomSelect
            name="codeType"
            value={values.codeType}
            options={['licence-code', 'referral-code', 'invite-code']}
            onChange={(e) => handleSelectFieldChange1(e)}
            onBlure={handleBlur}
            placeholder='Select Code Type'
            label='Code Type'
          />

          {values.codeType === 'invite-code' && (
            <SearchableSelect
              options={emailList}
              value={values.email}
              onChange={(value: string) => setFieldValue('email', value)}
              placeholder="Search for an email..."
            />
          )}

          {values.codeType === 'licence-code' && (
            <>
              <CustomSelect
                label='Business Client'
                name="business_client"
                value={values.business_client}
                options={businesses? businesses.map((item) => ({ name: item.name, id: item.id })): []}
                onChange={async(e) => await handleSelectFieldChange1(e)}
                placeholder='Select Business Client'
                onBlure={handleBlur}
                error= {(formik.errors.business_client && formik.touched.business_client)?
                  formik.errors.business_client : ''}
              />
              
            {values.business_client && 
             (<> 
              <CustomSelect
                label='Contract Reference'
                name="contract_ref"
                value={values.contract_ref}
                options={filteredContracts
                  ? filteredContracts
                      .filter((item) => item.contract_ref && item.id) // Ensures both are defined
                      .map((item) => ({ name: item.contract_ref as string, id: item.id as string })) // Explicitly cast to string
                  : []}
                onChange={async(e) => await handleContractRefChange(e) }
                onBlure={handleBlur}
                placeholder='Select Contract Referrence'
                error= {(formik.errors.contract_ref && formik.touched.contract_ref )?
                  formik.errors.contract_ref : ''}
              />
                  {values.contract_ref &&
                    <> <CustomInput
                        label= {`Remaining Licences in the selected contract`}
                        name="contract_left_licences"
                        type="number"
                        value={openLicenceCount}
                        onChange= {async (e) => await handleSelectFieldChange1(e)}
                        disabled={true}
                      /> 
                      <CustomSelect
                        label='Role Type'
                        name="role_type"
                        value={values.role_type}
                        options={[{ name: 'Client', id: 'client' }, { name: 'Advisor', id: 'advisor' }]}
                        onChange={async(e) => await handleSelectFieldChange1(e)}
                        placeholder='Select Business Client'
                        onBlure={handleBlur}
                        error= {(formik.errors.role_type && formik.touched.role_type)?
                          formik.errors.role_type : ''}
                      />           
                    
                    { values.role_type == 'advisor' && 
                        <CustomMultiSelect
                          name="roles"
                          value={values.roles}
                          onChange={value => handleSelectFieldChange2({name:'roles', value: value})}
                          options={roleList}
                          label='Roles To Assign'
                          error={
                            Array.isArray(formik.errors.roles)
                              ? formik.errors.roles.join(', ') // Combine all error messages into a single string
                              : formik.errors.roles || ''
                          }
                        />}

                    <CustomInput
                      label='Number of Licences to generate'
                      name="number_of_codes_to_generate"
                      type="number"
                      min={1}
                      max={values.contract_left_licences}
                      value={values.number_of_codes_to_generate}
                      onChange={async (e) => await handleSelectFieldChange1(e)}
                      onBlure={handleBlur}
                      placeholder="Number of licences to generate"
                      error= {(formik.errors.number_of_codes_to_generate && formik.touched.number_of_codes_to_generate) ?
                        formik.errors.number_of_codes_to_generate : ''}
                    />
                  </> }
              </>)}  
            </>)}

          {values.codeType === 'referral-code' && (
            <>
              <CustomSelect
                name="business_client"
                value={values.business_client}
                options={businesses? businesses.map((item) => ({ name: item.name, id: item.id })): []}
                onChange={(e) => handleSelectFieldChange1(e)}
                placeholder='Select Business Client Owner Of This Code'
                label='Business Client'
                error= {(formik.errors.business_client && formik.touched.business_client) ?
                  formik.errors.business_client : ''}
              />
              <CustomSelect
                name="for_business_client"
                value={values.for_business_client}
                options={businesses? businesses.map((item) => ({ name: item.name, id: item.name})): []}
                onChange={(e) => handleSelectFieldChange1(e)}
                placeholder='Select Business Client That Will Share This Code'
                label='Business Client That will share the code'
                error= {(formik.errors.for_business_client && formik.touched.for_business_client) ?
                  formik.errors.for_business_client : ''}
              />
              <CustomInput
                label='Prefix'
                name="prefix"
                type="text"
                value={values.prefix}
                onChange={(e) => handleSelectFieldChange1(e)}
                placeholder="Enter a Prefix"
                error= {(formik.errors.prefix && formik.touched.prefix)?
                  formik.errors.prefix : ''}
              />
              <CustomInput
                label='Expiry Date'
                name="expire_at"
                type="date"
                value={values.expire_at}
                onChange={(e) => handleSelectFieldChange1(e)}
                placeholder="Select Expiry date"
                error= {(formik.errors.expire_at && formik.touched.expire_at)?
                  formik.errors.expire_at : ''}
              />
              {/*<CustomInput
                label='number of codes to generate'
                name="number_of_codes_to_generate"
                type="number"
                value={values.number_of_codes_to_generate}
                onChange={handleChange}
                placeholder="Number of referral codes to generate"
                error= {(formik.errors.number_of_codes_to_generate && formik.touched.number_of_codes_to_generate) ?
                  formik.errors.number_of_codes_to_generate : ''}
              />*/}
              <CustomInput
                label='Use Limit'
                name="useLimit"
                type="number"
                value={values.useLimit}
                onChange={(e) => handleSelectFieldChange1(e)}
                placeholder="Maximum number of users to use this access code"
                error= {(formik.errors.useLimit && formik.touched.useLimit) ?
                  formik.errors.useLimit : ''}
              />
            </>
          )}
        {values.codeType &&
          <CustomButton
            title={
              values.codeType === 'licence-code'
                ? 'Generate Licences'
                : values.codeType === 'referral-code'
                ? 'Generate Referral Code'
                : 'Generate Invite Code'
            }
            name="generateAccessCode"
            handleClick={handleFormSubmit} 
            type="submit"
            loading={loading}
            disabled={loading}
          />}
        </form>
      </div>
    </div>
  );
};

export default GenerateAccessCode;
