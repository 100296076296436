import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import CustomSelect from '../../components/CustomSelectInput';
import CustomButton from '../../components/CustomButton';
import useCustomStyles from '../../hooks/useCustomStyles';
import { customStyles } from '../../assets/styles/notificationForm';
import { selectTranslationState } from '../../store/slices/languageSlice';
import { selectState } from '../../store/slices/themeSlice';
import CustomInput from '../../components/CustomInput';
import { AppDispatch, RootState } from '../../store/store';
import { AccessCodeType, addAccessCode, ContractType, getContracts } from '../../store/actions/accessCodeActions';
import CustomMultiSelect from '../../components/CustomMultiSelect';
import validationSchema from './scripts';
import { Option } from '../../components/CustomMultiSelect';
import { listenToLicenceCountUpdates, listenToLicenceValidatedUpdates } from '../../db/listners';
import { setContracts, setLicence } from '../../store/slices/accessCodeSlice';
import { useNavigate } from 'react-router-dom';
import { addSevenDays, getCurrentDateFormatted } from '../../utils';

const GenerateLicences = () => {
  const { t } = useSelector(selectTranslationState);
  const { theme, activeTheme } = useSelector(selectState);
  const { user } = useSelector((state: RootState) => state.auth_slice)
  const { contracts, loading } = useSelector((state: RootState) => state.accessCode_slice);
  const classes = useCustomStyles(customStyles, theme);
  const [filteredContracts, setFilteredContracts] = useState(contracts)
  const [openLicenceCount, setOpenLicenceCount] = useState(0)
  const [contractExpiry, setContractExpiry] = useState<string |Date| undefined>('')
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  
  useEffect(() => {
    // Define the function that updates the state (or dispatches a Redux action)
    const handleLicenseUpdate = (accessCode: AccessCodeType) => {
      // Example of dispatching a Redux action
      dispatch(setLicence(accessCode));
    };
    
    const updateContractsInState = (contracts: ContractType[]) => {
      // Dispatch an action to update the entire contracts list in the Redux store
      dispatch(setContracts(contracts));  // Ensure this action is set up in your Redux slice
    };
    // Start listening to licence validated updates
    const unsubscribeLicence = listenToLicenceValidatedUpdates(handleLicenseUpdate);

    // Start listening to licence count updates
    const unsubscribeContract = listenToLicenceCountUpdates(updateContractsInState);

    // Cleanup listener when the component unmounts
    return () => {
      if (unsubscribeLicence) {
        unsubscribeLicence();  // Unsubscribe from licence updates
      }
      if (unsubscribeContract) {
        unsubscribeContract();  // Unsubscribe from contract updates
      }
    };
  }, []);

  const roleList = [
    //{label: 'Marketing', value: 'Marketing'}, 
    {label: 'CRM', value: 'CRM'},
    {label: 'Administrator', value: 'Administrator'}]
  
  const formik = useFormik({
    initialValues: {
      roles: [],
      role_type: 'client',
      codeType: 'licence-code',
      contract_ref: '',
      contract_left_licences: 0,
      business_client: user?.business?.id || '',
      partner: '',
      email: '',
      expire_at: addSevenDays(getCurrentDateFormatted()),
      useLimit: 1,
      number_of_codes_to_generate: 1,
    },
    validationSchema: validationSchema,
    onSubmit: async(values) => {
      await dispatch(addAccessCode({ 
          role_type: values.role_type, 
          roles: values.roles,
          expire_at: contractExpiry?.toString(),
          codeType: values.codeType,
          contract_ref: values.contract_ref,
          business_client: values.business_client,
          number_of_codes_to_generate: values.number_of_codes_to_generate,
          navigate
        }))
      },
    validateOnChange: false, // Disable validation on change
    validateOnBlur: false,   // Disable validation on blur
  }); 
  
  const { values, setFieldValue, handleBlur,handleSubmit, setFieldTouched } = formik;

  const handleGetContracts = ()=>{
    dispatch(getContracts({business_client: user?.business?.id || ''}))
  }
  
  
  useEffect(() => {
    if(!contracts ){
      handleGetContracts();
    }
   
  }, [])

  useEffect(() => {
   // console.log(' fetch contracts for business', formik.values.business_client)
    if(contracts && user?.business){
        setFilteredContracts(contracts.filter((item: ContractType )=> item.business_client == user?.business?.id));  
    }
      
  }, [contracts])


  // Wrapper function to handle form submission from button click
  const handleFormSubmit = async(event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    await formik.submitForm();
  };
  
  const handleContractRefChange = async(e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    await setFieldValue(name, value);
    await setFieldTouched(name, true);
    const valid = await validationSchema.validateAt(name, { [name]: value });
    if(valid){
      formik.setFieldError(name, undefined);  // Clear the error if validation passes
      const selectedContract = filteredContracts?.filter(item => item.id == e.target.value)[0]
      if(selectedContract){
        setOpenLicenceCount(selectedContract.useLimit - selectedContract.licence_count)
        setFieldValue('contract_left_licences', selectedContract.useLimit - selectedContract.licence_count)
        setContractExpiry(selectedContract.expire_at)
      }
    }
   
  }

  const handleSelectFieldChange1 = async(e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) =>{
    const { name, value } =  e.target;  
    if(name == 'business_client'){
      await setFieldValue('contract_ref', '');
      setOpenLicenceCount(0)
      setFieldValue('contract_left_licences', 0)
    }
    await setFieldValue(name, value);
    await setFieldTouched(name, true);
    if(name == 'role_type' && value == 'client'){
      await setFieldValue('roles', []);
    }

    const valid = await formik.validateForm();
    if(valid){ 
      console.log('field validated', name, value)
    }else{
      console.log('field not validated', name, formik.errors)
    }
  }

  const handleSelectFieldChange2 = async(e: {name: string, value: string| number | Option[]}) =>{
    const { name, value } =  e;  
    await setFieldValue(name, value);
    await setFieldTouched(name, true);
    const valid = await formik.validateForm();
    if(valid){ 
      console.log('field validated', name, value)
    }else{
      console.log('field not validated', name, formik.errors)
    }
  }
  
  useEffect(() => {
    console.log('formik values / errors', formik.errors, formik.values)
  }, [formik.errors])
  
  
  return (
    <div className={classes?.container}>
    <div className={classes?.notificationForm}>
      <h1 className={classes?.title}>{t[activeTheme].generateLicencesScreen.form.title}</h1>
      <hr className={classes?.divider} />

      <form onSubmit={handleSubmit} className={classes?.notificationForm}>
        <CustomSelect
          label={t[activeTheme].generateLicencesScreen.form.contractRefInput.label}
          name="contract_ref"
          value={values.contract_ref}
          options={filteredContracts
            ? filteredContracts
                .filter((item) => item.contract_ref && item.id)
                .map((item) => ({ name: item.contract_ref as string, id: item.id as string }))
            : []}
          onChange={async (e) => await handleContractRefChange(e)}
          onBlure={handleBlur}
          placeholder={t[activeTheme].generateLicencesScreen.form.contractRefInput.placeholder}
          error={formik.errors.contract_ref ? formik.errors.contract_ref : ''}
        />

        {values.contract_ref && (
          <>
            <CustomInput
              label={t[activeTheme].generateLicencesScreen.form.remainingLicencesInput.label}
              name="contract_left_licences"
              type="number"
              value={openLicenceCount}
              onChange={async (e) => await handleSelectFieldChange1(e)}
              disabled
            />

            <CustomInput
              label={t[activeTheme].generateLicencesScreen.form.subscriptionExpiryInput.label}
              name="contract_expiry_date"
              type="text"
              value={contractExpiry}
              onChange={()=>{return;}}
              disabled
            />

            <CustomSelect
              label={t[activeTheme].generateLicencesScreen.form.roleTypeInput.label}
              name="role_type"
              value={values.role_type}
              options={[
                { name: 'Client', id: 'client' },
                { name: 'Advisor', id: 'advisor' },
              ]}
              onChange={async (e) => await handleSelectFieldChange1(e)}
              placeholder={t[activeTheme].generateLicencesScreen.form.roleTypeInput.placeholder}
              onBlure={handleBlur}
              error={formik.errors.role_type && formik.touched.role_type
                ? formik.errors.role_type
                : ''}
            />

            {values.role_type === 'advisor' && (
              <CustomMultiSelect
                name="roles"
                value={values.roles}
                onChange={(value) => handleSelectFieldChange2({ name: 'roles', value })}
                options={roleList}
                label={t[activeTheme].generateLicencesScreen.form.rolesInput.label}
                error={
                  Array.isArray(formik.errors.roles)
                    ? formik.errors.roles.join(', ') // Combine all error messages into a single string
                    : formik.errors.roles || ''
                }
              />
            )}

           {/* <CustomInput
              label={t[activeTheme].generateLicencesScreen.form.ExpiryDateInput.label}
              name="expire_at"
              type="date"
              placeholder={addSevenDays(getCurrentDateFormatted())}
              value={values.expire_at}
              onChange={async (e) => await handleSelectFieldChange1(e)}
              max={contractExpiry && contractExpiry.toString()}
            />*/}
{/*
            <CustomInput
              label={t[activeTheme].generateLicencesScreen.form.numberOfLicencesInput.label}
              name="number_of_codes_to_generate"
              type="number"
              min={1}
              max={values.contract_left_licences}
              value={values.number_of_codes_to_generate}
              onChange={async (e) => await handleSelectFieldChange1(e)}
              onBlure={handleBlur}
              placeholder={t[activeTheme].generateLicencesScreen.form.numberOfLicencesInput.placeholder}
              error={formik.errors.number_of_codes_to_generate && formik.touched.number_of_codes_to_generate
                ? formik.errors.number_of_codes_to_generate
                : ''}
            />*/}
          </>
        )}

        <CustomButton
          title={t[activeTheme].generateLicencesScreen.form.generateButton.title}
          name="generateAccessCode"
          handleClick={handleFormSubmit}
          type="submit"
          loading={loading}
          disabled={loading}
        />
      </form>
    </div>
  </div>
  );
};

export default GenerateLicences;
