import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../store/actions/authActions';
import { AppDispatch } from '../store/store';

const worker = new Worker(`${process.env.PUBLIC_URL}/logoutWorker.js`);

const AutoLogout: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const INACTIVITY_LIMIT = 15 * 60 * 1000; // 15 minutes

  const resetInactivityTimer = useCallback(() => {
    const currentTime = Date.now();
    localStorage.setItem('lastActivityTime', currentTime.toString());

    // Send the updated time to the worker
    worker.postMessage({ type: 'SET_LAST_ACTIVITY', lastActivityTime: currentTime });
  }, []);

  useEffect(() => {
    const handleTabCloseOrRefresh = (event: BeforeUnloadEvent) => {
      console.log('event.returnedValue', event)
      sessionStorage.setItem('isClosing', 'true');
    };

    const handleUnload = () => {
      const isClosing = sessionStorage.getItem('isClosing');

      if (isClosing === 'true') {
        dispatch(logout());
        localStorage.setItem('isLoggedOut', 'true');
        sessionStorage.removeItem('isClosing');
      }
    };

    // Add the event listeners
    window.addEventListener('beforeunload', handleTabCloseOrRefresh);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleTabCloseOrRefresh);
      window.removeEventListener('unload', handleUnload);
    };
  }, [dispatch]);

  useEffect(() => {
    const storedTime = localStorage.getItem('lastActivityTime');
    const lastActivityTime = storedTime ? parseInt(storedTime, 10) : Date.now();

    // Send the retrieved last activity time to the worker
    worker.postMessage({ type: 'SET_LAST_ACTIVITY', lastActivityTime });
    worker.postMessage({ type: 'SET_INACTIVITY_LIMIT', limit: INACTIVITY_LIMIT });

    worker.onmessage = (event) => {
      if (event.data.type === 'LOGOUT') {
        dispatch(logout());
        localStorage.setItem('isLoggedOut', 'true');
      }
    };

    // Add event listeners to reset inactivity timer on user activity
    window.addEventListener('mousemove', resetInactivityTimer);
    window.addEventListener('keydown', resetInactivityTimer);

    return () => {
      window.removeEventListener('mousemove', resetInactivityTimer);
      window.removeEventListener('keydown', resetInactivityTimer);
      worker.terminate();
    };
  }, [dispatch, resetInactivityTimer]);

  useEffect(() => {
    const isLoggedOut = localStorage.getItem('isLoggedOut');
    if (isLoggedOut === 'true') {
      dispatch(logout());
      localStorage.removeItem('isLoggedOut'); // Clear flag after handling
    }
  }, [dispatch]);

  return null;
};

export default AutoLogout;
