
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useCustomStyles from '../../hooks/useCustomStyles';
//import { customStyles } from '../../assets/styles/notificationForm';
import { selectState } from '../../store/slices/themeSlice';
import { AppDispatch, RootState} from '../../store/store';
import { getAdvisorsAction, getClientsAction } from '../../store/actions/clientsActions';
import { ClientType } from '../../store/slices/clientsSlice';
import { useNavigate } from 'react-router-dom';
import CustomTableRow from '../../components/CustomTableRow';
import { capitalizeFirstLetter } from '../../utils';
import { accessCodesStyles } from '../../assets/styles/accessCodesStyles';


const Clients = () => {
   
    const { theme } = useSelector(selectState);
    const classes = useCustomStyles(accessCodesStyles, theme);
    const {user} = useSelector((state: RootState)=> state.auth_slice);
    const {clients} = useSelector((state: RootState)=> state.clients_slice);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate()
    
    useEffect(() => { 
      dispatch(getClientsAction({business_name: user?.business?.name}))
      dispatch(getAdvisorsAction({business_name: user?.business?.name}))
    }, [])


    const handleClientClick = (client: ClientType) => {
        navigate(`/clientDetails`, { state: { client } });
      };

  return (
      <div className={classes.container}>
      <div className={classes.tableContainer}>
        <h2 className={classes.header}>MANAGE CLIENTS</h2>
      </div>   
      <table className={classes.table}>
        <thead>
          <CustomTableRow key={`#index-thead-client`} 
            items={['Name','Email',
            'Advisors','']} 
            index={-1} isHeader={true}/>
        </thead>
        <tbody>
          {clients &&
          clients.map((client, index) => (
          <CustomTableRow 
              
              key={`#index-client-row--${index}`} 
              items={[
                capitalizeFirstLetter(client.user_name),
                client.email, 
                (client.advisors && client.advisors.length > 0)? `(${client.advisors.length} ${client.advisors.length > 1? 'advisors' : 'advisor'})`: '']} 
                index={index+1} 
                client={client} 
                handleNavigate={handleClientClick}/>
        ))}
        </tbody>
      </table>
     
    </div>
  );
};

export default Clients;
